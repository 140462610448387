<template>
  <div class="register">
    <div class="container h-100">
      <div class="row h-100">
        <form @submit.prevent="submit" class="col-xl-4 col-ml-5 col-lg-6 m-auto h-100">
          <div class="card_register h-100">
            <div>
              <h1 :class="{ active: !number }">
                {{ $localize('sms-code')['title'] }}
              </h1>
              <div class="form_input">
                <p v-if="number" class="number">
                  {{ $localize('sms-code')['text'] }} <span> {{ number }}</span>
                </p>
                <sms-code-input
                  v-model="smsCode"
                  class="field-container"
                  :length="4"
                  input-type="tel"
                  pattern="[^0-9]+"
                  :ignorePattern="false"
                  fieldClass="custom-field-class"
                  :size="32"
                  :disabled="false"
                />
                <p @click="resendSMS($event)">
                  <span class="timer" :class="{ active }">
                    {{ $localize('sms-code')['timer'] }}
                  </span>
                  {{ isLanguageRU ? $localize('sms-code')['timer-value'] : '' }}
                  {{ timer }}
                  {{
                  isLanguageRU
                  ? $localize('sms-code')['timer-time']
                  : $localize('sms-code')['timer-time-uz']
                  }}
                </p>
              </div>
            </div>
            <div class="mt-auto margin-top-auto">
              <main-button :is-loading="loading" type="submit">
                {{ $localize('button')['continue'] }}
              </main-button>
              <p-button
                :to="{ name: 'auth' }"
                background="rgba(102, 16, 245, 0.1);"
                color="#6610F5"
                class="btn_ligth"
              >
                {{ $localize('button')['number-change'] }}
              </p-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '../../components/buttons/MainButton';

export default {
  name: 'smsCode',
  data: () => ({
    loading: false,
    smsCode: '',
    check: false,
    number: localStorage.getItem('number'),
    timer: 60,
    active: false,
    disabled: false,
  }),
  components: { MainButton },
  computed: {
    isLanguageRU() {
      return !!(
        !localStorage.getItem('lang') ||
        localStorage.getItem('lang') === 'ru-RU'
      );
    },
    purchasedProduct() {
      return this.$store.getters['buyers/purchasedProduct']
    }
  },
  methods: {
    async resendSMS(e) {

      if (this.timer === 0) {
        const num = this.number.replace(/[^0-9]/g, '');
        await this.$axios.post('login/send-sms-code', { phone: num });
        this.smsCode = '';
        this.timer = 60;
      } else {
        e.preventDefault();
      }
      this.active = false;
    },
    submit: async function () {
      this.loading = true;
      this.disabled = true;

      const phoneNumber = this.number.replace(/[^0-9]/g, '');
      this.$toasted.clear();

      if (this.smsCode !== '' && this.smsCode.length >= 4) {
        try {
          const res = await this.$axios.post('login/auth', {
            phone: phoneNumber,
            code: this.smsCode,
          });

          if (res.data.status === 'success') {
            this.loading = false;
            // заглушка: PAYMART-1058
            if (res.data.data.user_status === 4) {
              localStorage.setItem('api_token', res.data.data.api_token);

              this.$cookieSet('token', res.data.data.api_token, 2);

              localStorage.setItem('user_number', this.$route.query.phone);

              if (res.data.data.id) {
                localStorage.setItem('user_id', res.data.data.id);
              } else {
                localStorage.setItem('user_id', res.data.data.user_id);
              }

              await this.$store.dispatch('buyers/userStatus');

              this.$toasted.clear();

              if (this.$route.query.redirect) {
                return await this.$router.push({
                  name: this.$route.query.redirect,
                  params: { id: this.$route.query?.contractId },
                });
              }

              return await this.$router.push({ name: 'profile-index' })
            } else {
              await this.$store.dispatch('buyers/signOut')
              return await this.$router.push({ name: 'auth-failed' })
            }





            // await this.$store.dispatch('buyers/userStatus');
            //
            // // response.data.data.status
            // const { data: { data: { status: userStatus, passport_type: passportType } } } = await this.$axios.get(`/buyer/check_status`);
            //

            //
            // if (passportType === 6) {
            //   switch (userStatus) {
            //     case 0:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'auth' });
            //       break;
            //     case 1:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'cardAdd' });
            //       break;
            //     case 2 || 6:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'loading' });
            //       break;
            //     case 4:
            //       this.$toasted.clear();
            //       await this.$router.push({
            //         name: 'profile-index',
            //       });
            //       break;
            //     case 5:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'upload-passport' });
            //       break;
            //     case 8:
            //       await this.$router.push({ name: 'refusal' });
            //       break;
            //     case 10:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'upload-selfie-passport' });
            //       break;
            //     case 11:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'upload-address-passport' });
            //       break;
            //     case 12:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'confidant' });
            //       break;
            //     default:
            //       await this.$router.push({ name: 'home' });
            //       break;
            //   }
            // } else if (passportType === 0) {
            //   switch (userStatus) {
            //     case 0:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'auth' });
            //       break;
            //     case 1:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'cardAdd' });
            //       break;
            //     case 2 || 6:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'loading' });
            //       break;
            //     case 4:
            //       this.$toasted.clear();
            //       await this.$router.push({
            //         name: 'profile-index',
            //       });
            //       break;
            //     case 5:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'upload-id-card' });
            //       break;
            //     case 8:
            //       await this.$router.push({ name: 'refusal' });
            //       break;
            //     case 10:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'upload-selfie-id-card' });
            //       break;
            //     case 11:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'upload-address-id-card' });
            //       break;
            //     case 12:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'confidant-id-card' });
            //       break;
            //     default:
            //       await this.$router.push({ name: 'home' });
            //       break;
            //   }
            // } else {
            //   switch (userStatus) {
            //     case 0:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'auth' });
            //       break;
            //     case 1:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'cardAdd' });
            //       break;
            //     case 2 || 6:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'loading' });
            //       break;
            //     case 4:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'profile-index' });
            //       break;
            //     case 5:
            //       this.$toasted.clear();
            //       await this.$router.push({ name: 'upload-passport' });
            //       break;
            //   }
            // }
            // this.loading = false;
          } else {
            // await this.$router.push(this.$route.path + '?error=notSms');
            this.$toastError(this.$localize('error')['message']['notSms']);
            this.loading = false;
          }
        } catch (e) {
          this.loading = false;
          this.$toastError(this.$localize('error')['text']);
        }
      } else {
        this.loading = false;
        this.$toastError(this.$localize('error')['message']['notSms']);
      }
    },
  },
  mounted() {
    const timer = setInterval(() => {
      if (this.timer === 0) {
        this.active = true;
      } else {
        this.timer -= 1;
      }
    }, 1000);

    if (this.timer === 0) {
      clearInterval(timer);
    }

    const input = document.querySelectorAll('.custom-field-class input');
    input.forEach((n) => {
      n.type = 'tel';
    });
  },
};
</script>

<style lang="scss" scoped>
  .register {
    background: $grey;
    height: calc(100vh - 100px);

    .card_register {
      padding-top: 112px;

      h1 {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
        color: $black;

        &.active {
          margin-bottom: 32px;
        }
      }
    }
  }

  .P_button {
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 8px;
    /*width: auto;*/
    padding: 18px 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background: $main-light;
    }
  }

  .m-auto {
    margin: auto;
  }

  /*button {*/
  /*  width: 100%;*/
  /*  background: transparent;*/
  /*  outline: none;*/
  /*  border: none;*/
  /*  height: 100%;*/

  /*  &.disabled {*/
  /*    cursor: not-allowed;*/
  /*  }*/
  /*}*/

  .form_input {
    margin-bottom: 32px;
    width: 100%;

    p {
      text-align: center;
    }

    .timer {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      display: inline-block;
      letter-spacing: 0.01em;
      color: $black;
      margin-top: 32px;

      &:hover {
        cursor: not-allowed;
      }

      &.active {
        cursor: pointer;
        color: $main;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .number {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black;
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 575px) {
    .register {
      height: calc(100vh - 48px);

      .card_register {
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
        padding-top: 48px;

        .mt-auto {
          margin-top: 48px;
        }

        .margin-top-auto {
          margin-top: auto;
        }
      }
    }
    .btn_ligth {
      margin-top: 8px;
      margin-bottom: 32px;
    }
    /*button {*/
    /*  width: 100%;*/
    /*  background: transparent;*/
    /*  outline: none;*/
    /*  border: none;*/
    /*  height: auto;*/
    /*}*/

    .P_button {
      width: 100%;
    }
    .register .card_register h1 {
      font-size: 24px;
      line-height: 40px;
      color: $black;
      margin-bottom: 8px;
    }
    .form_input p {
      display: flex;
      flex-direction: column;
    }
    .form_input .number {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.24px;
      display: flex;
      flex-direction: column;
      margin-bottom: 38px;
    }
    .form_input .timer {
      font-size: 16px;
      line-height: 20px;
    }
    .h-100 {
      height: 100%;
    }
  }
</style>
<style lang="scss">
  .field-container {
    display: flex;
    justify-content: center;

    .custom-field-class {
      padding: 0px 8px;

      input {
        font-family: 'Inter', sans-serif;
        width: 67.05px;
        height: 67.05px;
        border: 1.1175px solid #626262;
        // padding-left: 24px;
        text-align: center;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 32px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .btn_ligth {
    margin-top: 24px;

    a {
      box-shadow: none !important;
    }
  }

  @media (max-width: 575px) {
    .field-container .custom-field-class {
      padding: 0 3px;

      input {
        width: 55px;
        height: 55px;
      }
    }
  }
</style>
